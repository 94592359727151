html {
  font-size: 62.5%;
}

body {
  font-family: Georgia, serif;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  margin: 0 auto;
  padding: 0 1.5em;
  max-width: 36em;
}

ul {
  padding: 0;
}

.list-inline li {
  display: inline;
  padding: 0 8px 0 0;
}

main ul {
  list-style: none;
  padding: 0;
}

a, a:hover, a:visited {
  color: rgb(0, 80, 230);
  text-decoration: none;
}

img {
  max-width: 100%;
}
